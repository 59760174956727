module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../plugins/layout-wrapper/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/layout-base/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../packages/gatsby-source-sushishop/gatsby-browser.js'),
      options: {"plugins":[],"exportPath":"static/locales","gatsby_env":"prod_ch","base_url":"https://www.mysushishop.ch","ssl_reject_unauthorized":true},
    },{
      plugin: require('../../../packages/gatsby-plugin-intl-sushishop/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","fr"],"defaultLanguage":"fr","redirect":true,"redirectDefaultLanguageToRoot":false,"ignoredPaths":[],"fallbackLanguage":"fr","localeJsonSourceName":"locales"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
